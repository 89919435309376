import React, { Fragment } from 'react';

import {getRapidNaming, getTestLanguage} from '../../constants/tests.js';

import StopWatch from '../../components/StopWatch';

import AdminTestPanel from '../../components/AdminTestPanel';
import AdminReadyButton from '../../components/AdminReadyButton';

import strings from '../../strings';

class Admin extends AdminTestPanel {
	constructor(props) {
		super(props, {
			
		});
		switch (strings.getLanguage()) {
			
			case 'de':
				
				switch(this.props.testKeyType){
					case 'child-followup':
						this.isChildType = true;
						this.rn_admin7 = strings.rn_admin7;	
						this.rn_admin7Chid = strings.rn_admin7Chid;
						this.rn_admin8	= strings.rn_admin8Child;
						this.testDone = strings.testDoneChild;
						this.nextTest = strings.nextSubtest;
						break;
						default:
							this.isChildType = true;
							this.rn_admin7 = strings.rn_admin7;
							this.rn_admin7Chid = ``;
							this.rn_admin8	= strings.rn_admin8;
							this.testDone = strings.testDone;
							this.nextTest = strings.nextTest;
				}
				break;

				}
	}
	getTestName = () => {
		return strings.rn_testname;
	};

	getImage = (color, item) => {
		const images = getTestLanguage().rnImages;
		if (images[color][item]) return images[color][item];
		console.error('Item ' + color + item + ' not found');
		return null;
	};

	updateTimeUsed = (timeUsed) => {
		this.setState({timeUsed: timeUsed});
	};

	renderIntro = () => {
		const {currentStep} = this.props;

		return (
			<div>
				<p>{strings.rn_admin1}</p>
				{currentStep === 0 && (
					<>
						<p>{strings.rn_admin2}</p>
						<button
							onClick={() => {
								this.props.nextStep();
							}}
						>
							{strings.startPracticeTasks}
						</button>
					</>
				)}
				{currentStep === 1 && (
					<>
						<p>
							{strings.rn_admin3}
							<br />
							<br></br>
							{strings.formatString(
								strings.expectedAnswer,
								<b style={{color:'orange'}}>{strings.rn_colors}</b>
							)}
						</p>
						<p>{strings.rn_adjustIfNotCorrect}</p>
						<button
							onClick={() => {
								this.props.nextStep();
							}}
						>
							{strings.rn_correctColors}
						</button>
					</>
				)}
				{currentStep === 2 && (
					<>
						<p>
							{strings.rn_admin4}
							<br />
						</p>
						<p>
							{strings.formatString(
								strings.expectedAnswer,
								<b style={{color:'orange'}}>{strings.rn_objects}</b>
							)}
						</p>
						<p>{strings.rn_adjustIfNotCorrect}</p>
						<button onClick={() => this.props.nextStep()}>
							{strings.rn_correctItems}
						</button>
					</>
				)}
				{currentStep === 3 && (
					<>
						<p>{strings.rn_admin5}</p>
						<p>{strings.rn_admin6}</p>
						<p>
							{strings.formatString(
								strings.expectedAnswer,
								<b style={{color:'orange'}}>{strings.rn_objectsWithColors}</b>
							)}
						</p>
						<button onClick={this.props.nextStep}>
							{strings.rn_correctColoredItems}
						</button>
					</>
				)}
				{currentStep === 4 && (
					<>
						<p>{this.rn_admin7}</p>
						{this.isChildType ===true && (
							<Fragment>
							<p>{this.rn_admin7Chid}</p>
						<p>{strings.hotkeyCorrect}
						<br/>
						{strings.hotkeyWrong}
						<br/>
						{strings.hotkeyUnanswered}
						</p>
						<p style={{color:'orange'}}>{strings.rn_Final_Not_child}</p>
							</Fragment>
						)}
						<p>
							{strings.getLanguage() === 'de'
								? strings.clickStartWhenReadyEmpty
								: strings.clickStartWhenReady}
						</p>
						<AdminReadyButton
							remoteIsReady={this.props.candidateReady}
							onClick={this.props.nextStage}
						/>
					</>
				)}
			</div>
		);
	};

	returnCorrectAnswer(color,animal){
		if(['tür'].includes(animal)){
		
			return `${color}e  Tür`;
		}
		else if (['hund','schuh','baum','mond'].includes(animal)){
			const capitalAnimal = this.capitalize(animal);
			return `${color}er  ${capitalAnimal}`;
		}
		else
		return `${color}er  ${animal}`;
	}

	capitalize(string){
		return string[0].toUpperCase() + string.slice(1).toLowerCase();
	  }

	renderTest = () => {
		const {currentStep} = this.state;
		const steps = getRapidNaming(this.props.ageGroup).getSteps();
		const testLang = getTestLanguage();

		return (
			<div>
				<p>
					<StopWatch autoStart integer onTick={this.updateTimeUsed} />
					<br />
					{this.rn_admin8}
					<br />
					{strings.rn_admin9}
					<br />
					{strings.hotkeyCorrect} 
					<br /> 
					{strings.hotkeyWrong}
					<br/>
					{strings.hotkeyUnanswered}
				</p>
				<p style={{color:'orange'}}>{strings.rn_Final_Not_child}</p>
				<p>
					{strings.formatString(
						strings.taskNumOf,
						currentStep + 1,
						steps.length
					)}
				</p>
				<p>
					{strings.formatString(
						strings.expectedAnswer,
						<b>
							{this.returnCorrectAnswer(
								strings[
									testLang.rnColors[steps[currentStep].color]
								],

							strings[testLang.rnItems[steps[currentStep].item]])
	}
						</b>
					)}
				</p>

				<p className={`adminItem`}>
					<img
						alt=""
						src={this.getImage(
							steps[currentStep].color,
							steps[currentStep].item
						)}
					/>
				</p>
				<button
					onClick={(e) => {
						e.stopPropagation();

						this.answerCorrect();
					}}
				>
					{strings.hotkeyButtonCorrect}
				</button>
				<button
					onClick={(e) => {
						e.stopPropagation();

						this.answerWrong();
					}}
				>
					{strings.hotkeyButtonWrong}
				</button>
				<button
					onClick={(e) => {
						e.stopPropagation();
						this.answerUnanswered();
					}}
				>
					{strings.hotkeyButtonUnanswered}
				</button>
			</div>
		);
	};

	renderWaiting = () => {
		return (
			<div>
				{this.isChildType ===false && (
					<p>
					{strings.formatString(
						this.testDone,
						strings.rn_testname
					)}
				</p>
				)}
				{this.isChildType ===true && (
					<Fragment>
					<p>
					{strings.formatString(
						this.testDone,
						strings.rn_testname
					)}
				</p>
					 <p>{strings.formatString(strings.ensureReadiness,strings.nextSubtest)}</p>
				</Fragment>

				)}
				<p>
					{strings.formatString(
						strings.rn_timeUsed,
						this.state.timeUsed
					)}
				</p>
				<button onClick={() => this.props.nextStage()}>
					{this.isChildType===false ? this.nextTest :strings.nextSubtest}
				</button>
			</div>
		);
	};
	answerCB = () => {
		this.props.startTimer();
	};
}

export default Admin;
