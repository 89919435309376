import React, { Fragment } from 'react';

import {getWorkingMemory} from '../../constants/tests.js';

import AdminTestPanel from '../../components/AdminTestPanel';
import strings from '../../strings';
import AdminReadyButton from '../../components/AdminReadyButton';

const WM_STAGES = {
	NUMBERS_INTRO: 0,
	NUMBERS_TRIAL: 1,
	WAITING_POST_TRIAL: 2,
	NUMBERS_TEST: 3,
	WAITING: 4,
	WORDS_INTRO: 5,
	WORDS_TRIAL: 6,
	WAITING_POST_TRIAL2: 7,
	WORDS_TEST: 8,
	WAITING2: 9,
};

/* 
	original value

	NUMBERS_INTRO: 0,
	NUMBERS_TEST: 1,
	WAITING: 2,
	WORDS_INTRO: 3,
	WORDS_TEST: 4,
	WAITING2: 5,

*/
class WorkingMemoryWordsAdmin extends AdminTestPanel {
	constructor(props) {
		super(props, {
			stopOnConsecutiveErrors: true,
			enableTimers: false,
		});

		switch (strings.getLanguage()) {
			
			case 'de':
				
				switch(this.props.testKeyType){
					case 'child-followup':
					this.isChildType = true;
					this.ws_admin1 =``;
					this.wsn_admin2=strings.wsn_admin2_child;
					this.wsn_admin2part2=strings.wsn_admin2part2_child;
					this.hotkeyDescription=``;
					this.wsn_admin3 = strings.wsn_admin3Child;
					this.expectedAnswerDetailed = strings.expectedAnswerDetailed;
					this.expectedAnswer = strings.expectedAnswerChild;
					this.praiseCandidate = strings.praiseCandidate;
					this.hotkeyCorrect = ``;
					this.hotkeyWrong = ``;
					this.waitingInstruction=strings.waitingForTestChild;
					this.listenCarefullyChild = strings.listenCarefullyChild;
					this.testDone = strings.testDoneChild;
					this.makeSureChildReady = strings.makeSureChildReady;
					this.nextTest = strings.nextTestChild;
					this.wsw_admin2 = strings.wsw_admin1Child;
					this.wsw_admin2part2 = strings.wsw_admin1part2Child;
					this.wsw_admin3 = strings.wsn_admin3Child;
					this.nextSubtest = strings.nextSubtest;
					this.firstWMnpart= strings.firstWMnpart;
					this.secondWmnpart= strings.secondWmnpart;
					this.firstWMwpart = strings.firstWMwpart;
					this.secondWmwpart = strings.secondWmwpart;
					/* this.introtext4 = strings.pd_testInstructionChild;
					this.introtext5 = strings.pd_introDoneChild; */
				
					break;
					default:
						this.isChildType = false;
						this.ws_admin1 =strings.ws_admin1;
						this.wsn_admin2=strings.wsn_admin2;
						this.wsn_admin2part2=strings.wsn_admin2part2;
						this.hotkeyDescription=strings.hotkeyDescription;
						this.wsn_admin3 = strings.wsn_admin3;
						this.expectedAnswer = strings.expectedAnswer;
						this.expectedAnswerDetailed = '';
						this.hotkeyCorrect = strings.hotkeyCorrect;
						this.hotkeyWrong = strings.hotkeyWrong;
						this.praiseCandidate = '';
						this.waitingInstruction= strings.waitingForTestChild;
						this.listenCarefullyChild = ``;
						this.testDone = strings.testDone;
						this.makeSureChildReady = ``;
						this.nextTest = strings.nextTest;
						this.wsw_admin2 = strings.wsw_admin2;
						this.wsw_admin2part2 = strings.wsw_admin2part2;
						this.wsw_admin3 = strings.wsw_admin3;
						this.nextSubtest = strings.nextTest;
						this.firstWMnpart= '';
						this.secondWmnpart= '';
						this.firstWMwpart = '';
						this.secondWmwpart = '';
						
						/* 
						this.introtext4=strings.pd_testInstruction;
						this.introtext5=strings.pd_introDone; */
					
						break;
				}
			
				break;
		}

	}

	getTestName = (flag,stage) => {
		console.log('This is the stage',stage);
		if(stage ===0 || stage ===5){
			return strings.testName_WorkingMemory;
		}
		if (flag === 'words') return strings.wsw_testname;
		else return strings.wsn_testname;
	};

	answerCorrect = (numberOfCorrect = true) => {
		const {results, correctAnswers, currentStage, currentStep} = this.state;
		const testName =
			currentStage === WM_STAGES.NUMBERS_TEST ? 'numbers' : 'words';

		const newResults = Object.assign({}, results, {
			[testName]: {
				...results[testName],
				[currentStep]: {
					isCorrect: numberOfCorrect,
					isAnswered: true,
				},
			},
		});

		this.setState({
			results: newResults,
			correctAnswers: correctAnswers + 1,
			consecutiveErrors: 0,
		});
		this.props.nextStep(newResults); //changing state object to regular object
	};

	answerWrong = () => {
		const {
			consecutiveErrors,
			currentStage,
			currentStep,
			results,
			options,
		} = this.state;

		const testName =
			currentStage === WM_STAGES.NUMBERS_TEST ? 'numbers' : 'words';

		const newResults = Object.assign({}, results, {
			[testName]: {
				...results[testName],
				[currentStep]: {
					isCorrect: false,
					isAnswered: true,
				},
			},
		});

		this.setState(
			{
				wrongAnswers: this.state.wrongAnswers + 1,
				results: newResults,
				consecutiveErrors: consecutiveErrors + 1,
			},
			() => {
				const currErrors = this.state.consecutiveErrors;
				const maxErrors = options.maxConsecutiveErrors;
				if (
					currErrors >= maxErrors &&
					options.stopOnConsecutiveErrors
				) {
					if (testName === 'words')
						this.props.submitResults(this.state.results);
					this.setState({
						consecutiveErrors: 0,
					});
					this.props.nextStage();
				} else {
					this.props.nextStep(this.state.results);
				}
			}
		);
	};

	answerUnanswered = () => {
		const {
			consecutiveErrors,
			currentStage,
			currentStep,
			results,
			options,
		} = this.state;

		const testName =
			currentStage === WM_STAGES.NUMBERS_TEST ? 'numbers' : 'words';

		const newResults = Object.assign({}, results, {
			[testName]: {
				...results[testName],
				[currentStep]: {
					isCorrect: false,
					isAnswered: false,
				},
			},
		});

		this.setState(
			{
				unanswered: this.state.unanswered + 1,
				results: newResults,
				consecutiveErrors: consecutiveErrors + 1,
			},
			() => {
				const currErrors = this.state.consecutiveErrors;
				const maxErrors = options.maxConsecutiveErrors;
				if (
					currErrors >= maxErrors &&
					options.stopOnConsecutiveErrors
				) {
					if (testName === 'words')
						this.props.submitResults(this.state.results);
					this.props.nextStage();
				} else {
					this.props.nextStep(this.state.results);
				}
			}
		);
	};

	handleKeyPress = (event) => {
		const {
			options: {limitHotkeysToTest, enableHotkeys},
			currentStage,
		} = this.state;

		if (
			(!limitHotkeysToTest || currentStage === 1 || currentStage === 3  || currentStage === 6 || currentStage === 8) &&
			enableHotkeys
		) {
			if (event.key === 'a') {
				this.answerCorrect();
			}
			if (event.key === 's') {
				this.answerWrong();
			}
			if (event.key === 'd') {
				this.answerUnanswered();
			}
		}
	};

	renderIntro = (version) => {
		return (
			<>
				<p>
					{this.ws_admin1}
					<br />
					{version === 'numbers'
						? this.wsn_admin2
						: this.wsw_admin2}
					<br />

					{version === 'numbers'
						? this.wsn_admin2part2
						: this.wsw_admin2part2}
				</p>

				<p>
					{strings.getLanguage() === 'de'
						? strings.clickStartWhenReadyEmpty
						: strings.clickStartWhenReady}
				</p>

				<p>{strings.workingMemoryClickStart}</p>
				<AdminReadyButton
					remoteIsReady={this.props.candidateReady}
					onClick={this.props.nextStage}
				/>
			</>
		);
	};
	renderButtonsForTrials = () => (
		<>
			<button
				onClick={(e) => {
					e.stopPropagation();
					this.props.nextStep();
				}}
			>
				{strings.hotkeyButtonCorrect}
			</button>
			<button
				onClick={(e) => {
					e.stopPropagation();
					this.props.nextStep();
				}}
			>
				{strings.hotkeyButtonWrong}
			</button>
			<button
				onClick={(e) => {
					e.stopPropagation();
					this.props.nextStep();
				}}
			>
				{strings.hotkeyButtonUnanswered}
			</button>
		</>
	);

	renderButtons = () => (
		<>
			<button
				onClick={(e) => {
					e.stopPropagation();
					this.answerCorrect();
				}}
			>
				{strings.hotkeyButtonCorrect}
			</button>
			<button
				onClick={(e) => {
					e.stopPropagation();
					this.answerWrong();
				}}
			>
				{strings.hotkeyButtonWrong}
			</button>
			<button
				onClick={(e) => {
					e.stopPropagation();
					this.answerUnanswered();
				}}
			>
				{strings.hotkeyButtonUnanswered}
			</button>
		</>
	);

	renderTrial = (type) => {
		const {currentStep} = this.state;

		const dataset =
			type === 'numbers'
				? getWorkingMemory().getNumberTrialSteps()
				: this.props.testKeyType ==='child-followup' ?getWorkingMemory().getWordTrialStepsChild() : getWorkingMemory().getWordTrialSteps();
		const unreversedRow = this.props.testKeyType ==='child-followup' ? dataset[currentStep].slice().join(' - ') :'';
		const row = dataset[currentStep].slice().reverse().join(' - ');

		return (
			<div>
				<p>
					<br />
					{type === 'numbers'
						? this.wsn_admin3
						: this.wsw_admin3}
					<br />
					{this.isChildType ===false && (
						<>
						{this.hotkeyDescription}
					<br />
					{this.hotkeyCorrect}
					<br />
					{this.hotkeyWrong}
					</>
					)}
				</p>
				
				{this.isChildType ===true && (
					<Fragment>

					<p>
					
					{type==='numbers'?
					strings.formatString(strings.expectedAnswer, <b style={{color:'orange'}}>{row}</b>):
					strings.formatString(strings.expectedAnswer,<b style={{color:'orange'}}>{row}</b>)}
				</p>
					<p>{this.praiseCandidate}</p>
			
					<p>
					
					{type==='numbers'?
					strings.formatString(this.expectedAnswerDetailed, 'Zahlenfolge', <b>{unreversedRow}</b>,<b style={{color:'orange'}}>{row}</b>):
					strings.formatString(this.expectedAnswerDetailed, 'Wortfolge', <b>{unreversedRow}</b>,<b style={{color:'orange'}}>{row}</b>)}
				</p>
				{currentStep +1 ===2 && (
					<p>{strings.remindChild}</p>
				)}
				<p>
						{strings.hotkeyCorrect}
					<br/>
					{strings.hotkeyWrong}
					<br/>
					{strings.hotkeyUnanswered}
					</p>
				</Fragment>
				)}
				{/* 
					
					*/}
			
					<p>
					{strings.formatString(
						strings.trialTaskNumOf,
						currentStep + 1,
						dataset.length
					)}
				</p>
				{this.isChildType ===false && (
					<Fragment>
				<p>{strings.formatString(this.expectedAnswer, <b style={{color:'orange'}}>{row}</b>)}</p>
				
				</Fragment>
				)}
					
				{this.renderButtonsForTrials()}
			</div>
		);
	};

	renderTest = (type) => {
		const {currentStep} = this.state;

		const dataset =
			type === 'numbers'
				? getWorkingMemory().getNumberSteps()
				: getWorkingMemory().getWordSteps();

		const row = dataset[currentStep].slice().reverse().join(' - ');

		return (
			<div>
				<p>
					<br />
					{type === 'numbers'
						? this.wsn_admin3
						: this.wsw_admin3}
					<br />
				</p>
				<p>{this.hotkeyDescription}</p>
				<p>{this.hotkeyCorrect}</p>
				<p>{this.hotkeyWrong}</p>
				{this.isChildType === true && (
					<Fragment>
						<p>{this.listenCarefullyChild}
						<p>{strings.hotkeyCorrect}</p>
						<p>	{strings.hotkeyWrong}</p>
						<p>	{strings.hotkeyUnanswered}</p>
					</p>
					</Fragment>
				)}
				<p>
					{strings.formatString(
						strings.taskNumOf,
						currentStep + 1,
						dataset.length
					)}
				</p>
				<p>
					{strings.formatString(this.expectedAnswer, <b style={{color:'orange'}}>{row}</b>)}
				</p>
				{this.renderButtons()}
			</div>
		);
	};

	renderWaiting = (type) => {
		return (
			<div>
				<p>
					{strings.formatString(
						this.testDone,
						type !== 'words'
							? strings.wsn_testname
							: strings.wsw_testname
					)}
				</p>
				{this.isChildType === true && (
					<p>{type === 'numbers' ?strings.formatString(this.makeSureChildReady,this.nextTest):
					strings.formatString(this.makeSureChildReady,this.nextSubtest)}</p>
				)}
				<button onClick={this.props.nextStage}>
					{ type === 'numbers' ?this.nextTest :this.nextSubtest}
				</button>
			</div>
		);
	};

	renderWaitingTrial = (type) => {
		return (
			<div>
				<p>{this.waitingInstruction}</p>
				
					<p>{strings.sp_intro3}</p>
				
				<button onClick={this.props.nextStage}>
					{strings.nextTest}
				</button>
			</div>
		);
	};

	render() {
		const {currentStage: stage} = this.state;

		return (
			<div className="lit-intro">
				<h1 className="lit-page-title">
					{stage === WM_STAGES.NUMBERS_INTRO ||
					stage === WM_STAGES.NUMBERS_TRIAL ||
					stage === WM_STAGES.NUMBERS_TEST ||
					stage === WM_STAGES.WAITING_POST_TRIAL ||
					stage === WM_STAGES.WAITING
						? this.getTestName('numbers',stage)
						: this.getTestName('words',stage)}
				</h1>
				{this.props.testKeyType ==='child-followup' && stage ===0 &&(
					<Fragment>
					<p style={{color:'orange'}}>{this.firstWMnpart}</p>
					<p style={{color:'grey'}}>{this.secondWmnpart}</p>
					
				<p style={{fontWeight:'bold',fontSize:'30px', color:'orange'}}>{this.firstWMnpart}</p>
					</Fragment>
				)}
				{this.props.testKeyType ==='child-followup' && stage ===5 &&(
					<Fragment>
					<p style={{color:'grey'}}>{this.firstWMwpart}</p>
					<p style={{color:'orange'}}>{this.secondWmwpart}</p>
					
				<p style={{fontWeight:'bold',fontSize:'28px', color:'orange'}}>{this.secondWmwpart}</p>
					</Fragment>
				)}
				{stage === WM_STAGES.NUMBERS_INTRO &&
					this.renderIntro('numbers')}
				{stage === WM_STAGES.NUMBERS_TRIAL &&
					this.renderTrial('numbers')}
				{stage === WM_STAGES.WAITING_POST_TRIAL &&
					this.renderWaitingTrial('numbers')}

				{stage === WM_STAGES.NUMBERS_TEST && this.renderTest('numbers')}
				{stage === WM_STAGES.WAITING && this.renderWaiting('numbers')}
				{stage === WM_STAGES.WORDS_INTRO && this.renderIntro('words')}
				{stage === WM_STAGES.WORDS_TRIAL && this.renderTrial('words')}
				{stage === WM_STAGES.WAITING_POST_TRIAL2 &&
					this.renderWaitingTrial('words')}

				{stage === WM_STAGES.WORDS_TEST && this.renderTest('words')}
				{stage === WM_STAGES.WAITING2 && this.renderWaiting('words')}
			</div>
		);
	}
}

export default WorkingMemoryWordsAdmin;
